.fixed-width-column {
    width: 260px;
}

.fixed-height-logo {
    height: 36px;
    width: auto;
    overflow: hidden;
}

.fixed-height-left-column {
    max-height: calc(100vh - 52px);
    overflow-y: auto;
}

.fixed-height-row {
    height: 52px;
    /* overflow: hidden; */
}

.width-fit-content {
    width: fit-content;
}

.height-fit-content {
    height: fit-content;
}

.max-width-content {
    max-width: 768px;
}

.icon-wrap {
    height: fit-content;
    width: fit-content;
    border-radius: 4px;
    display: flex;
}

.icon-wrap:hover {
    background-color: rgb(204, 204, 204, 0.1);
    cursor: pointer;
    color: #ffffff
}

.icon-wrap:hover * {
    color: #ffffff
}

.icon-height {
    height: 24px;
    width: auto;
}

.custom-scrollbar {
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: #212529 #000000;
    /* Thumb color and track color for Firefox */
}

/* Webkit (Chrome, Safari, Edge) */
.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    /* Ширина скроллбару */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #000000;
    /* Колір фону треку */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #212529;
    /* Колір ползунка */
    border-radius: 10px;
    /* Округлення ползунка */
    border: 2px solid #000000;
    /* Проміжок між треком і ползунком */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #212529;
    /* Колір ползунка при наведенні */
}