.form {
  display: flex;
  height: 52px;
  border-radius: 26px;
  overflow: hidden;
}

.input {
  border: none;
  background-color: transparent;
  border-radius: 0;
  width: 100%;
  font-size: 1.2em;
  width: 100%;
  height: fit-content;
  margin: auto auto auto 26px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.sendButton {
  width: fit-content;
  height: fit-content;
  margin: auto 26px auto auto;
  cursor: pointer;
}