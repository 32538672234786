.list-questions-item {
    border-radius: 4px;
}

.list-questions-item:hover {
    background-color: rgb(204, 204, 204, 0.1) !important;
    cursor: pointer;
}

.list-questions-item-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.list-questions-item:hover * {
    color: #ffffff
}